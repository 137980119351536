<!-- 首页 -->
<template>
    <div class='home-container'>
        <div class="banner">
            <img src="../../assets/images/banner.png" alt="">
        </div>
        <div class="container">
            <div class="template1">
                <h1>了解课程</h1>
                <h2>COURSE FEATURES</h2>
                <div class="info">
                    <el-row type="flex" justify="space-between">
                        <el-col :span="8">
                            <div class="item">
                                <img src="../../assets/images/img1.png" alt="">
                                <div class="describe">
                                    <h4>强体魄</h4>
                                    <p>体格强壮、心理健康、性格坚韧</p>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="item">
                                <img src="../../assets/images/img3.png" alt="">
                                <div class="describe">
                                    <h4>尚品行</h4>
                                    <p>做人、做中国人、做现代中国人</p>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="item">
                                <img src="../../assets/images/img1.png" alt="">
                                <div class="describe">
                                    <h4>扬个性</h4>
                                    <p>个性鲜明、勤于思考、勇于探索</p>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>

        </div>
        <div class="template2">
            <div class="c-bg">
                <div class="container">
                    <h1>培训资源</h1>
                    <h2>TRAINING RESOURCES</h2>
                    <div class="info ">
                        <img src="../../assets/images/com.png" alt="">
                        <div class="info-t">
                            <p>幼儿园适应性发展课程依托树人云智慧平台，打造全新的教师线上学习资源平台。树人云智慧为广大幼儿园教师提供系统性的专业成长路径：通识性理论讲座、课堂实践、教学设计与论文资源等等。
                            </p>
                            <a href="https://www.sryzh.com/course/explore?subCategory=&selectedthirdLevelCategory=&tag[tags][4]=24&filter[type]=all&filter[price]=all&filter[currentLevelId]=all&orderBy=latest" target="_blank">
                                查看更多 >
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg"></div>

        </div>
        <div class="template3">
            <div class="container">
                <h1>动态资讯</h1>
                <h2>DYNAMIC INFORMATION</h2>
                <div class="info-wrap">
                    <img src="../../assets/images/img4.png" alt="">
                    <div class="tabs">
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="最新资讯" name="1">
                                <div v-infinite-scroll="load" class="infinite-list" style="overflow:auto;">
                                    <router-link to="">
                                        <div class="item">
                                            <div class="top">
                                                <div class="left">
                                                    <img src="../../assets/images/icon.png" alt="">
                                                    <span>遵义市红花岗区举办适应性发展课程建设推进会...</span>
                                                </div>
                                                <div class="right">2021-03-17 </div>
                                            </div>
                                            <div class="info">
                                                <p>2021年3月11日，遵义市红花岗区教育局在区第二幼儿园会议室举行了贵州省幼儿园适应性发展课程推进会，通过专题讲...</p>
                                            </div>
                                        </div>
                                    </router-link>
                                </div>

                                <router-link to="/consult">
                                <div class="more">
                                        <span>更多</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </router-link>
                            </el-tab-pane>
                            <el-tab-pane label="通知公告" name="2">通知公告</el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>
        <div class="template4">
            <div class="container">
                <h1>专家团队</h1>
                <h2>EXPERT TEAM</h2>
                <div class="teacher-info">
                    <div class="item">
                        <img src="../../assets/images/csj.png" alt="">
                        <div class="text-container">
                            <p class="name">陈时见</p>
                            <p class="t-info">西南大学副校长，教授，博士生导师。人民政府副市长。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../../assets/images/zlh.png" alt="">
                        <div class="text-container">
                            <p class="name">翟理红</p>
                            <p class="t-info">贵阳幼儿师范高等专科学校校长、教授。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../../assets/images/sgm.png" alt="">
                        <div class="text-container">
                            <p class="name">苏贵民</p>
                            <p class="t-info">西南大学教育学部副教授，OMEP中国委员会委员，美国芝麻街教育顾问。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../../assets/images/lfx.png" alt="">
                        <div class="text-container">
                            <p class="name">李凤霞</p>
                            <p class="t-info">贵阳幼儿师范高等专科学校副教授 。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../../assets/images/yl.png" alt="">
                        <div class="text-container">
                            <p class="name">杨丽</p>
                            <p class="t-info">贵阳幼儿师范高等专科学校副教授 。</p>
                        </div>
                    </div>
                    <div class="item">
                        <img src="../../assets/images/zjh.png" alt="">
                        <div class="text-container">
                            <p class="name">张剑辉</p>
                            <p class="t-info">贵阳幼儿师范高等专科学校副教授 。</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            activeName: '1'
        };
    },
    //方法集合
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        load() { }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
}
</script>
<style lang='less' scoped>
.home-container {
    .banner {
        width: 100%;
        img {
            width: 100%;
        }
    }

    h1 {
        font-size: 42px;
        color: #333333;
        font-weight: 400;
    }

    h2 {
        font-size: 28px;
        color: #474747;
        font-weight: 400;
        margin-top: 6px;
    }

    .template1 {
        margin-top: 130px;
        text-align: center;

        .info {
            margin-top: 40px;
        }

        .item {
            width: 360px;

            img {
                width: 100%;
                height: 226px;
            }

            .describe {
                height: 127px;
                background: #F5F5F5;

                h4 {
                    padding-top: 20px;
                }

                p {
                    margin-top: 16px;
                }
            }
        }
    }

    .template2 {
        margin-top: 115px;
        position: relative;
        height: 848px;

        .c-bg {
            width: 100%;
            height: 568px;
            background: url(../../assets/images/bg1.png)no-repeat;
            background-size: cover;
            text-align: center;

            h1 {
                margin-top: 80px;
            }

            h2 {
                margin-top: 21px;
            }
        }

        .bg {
            position: absolute;
            top: 568px;
            width: 100%;
            height: 280px;
            background: linear-gradient(135deg, #C7EA52 0%, #2FE4F5 100%);
            opacity: 0.68;
        }

        .info {
            position: relative;
            margin: 0 auto;
            z-index: 999;
            margin-top: 60px;
            display: flex;

            img {
                z-index: 10;
            }

            .info-t {
                height: 420px;
                background: #fff;
                margin-left: -30px;
                z-index: 1;
                margin-top: 10px;

                p {
                    text-align: left;
                    padding: 36px 42px 0 54px;
                    font-weight: 400;
                    font-size: 18px;
                    color: #474747;
                    line-height: 34px;
                    position: relative;

                    &::before {
                        content: "";
                        width: 4px;
                        height: 24px;
                        background: #FFA60A;
                        position: absolute;
                        top: 42px;
                        left: 40px;
                    }
                }

                a {
                    display: block;
                    color: #fff;
                    width: 168px;
                    height: 44px;
                    background: #FFA60A;
                    border-radius: 60px;
                    font-size: 20px;
                    line-height: 44px;
                    text-align: center;
                    margin: 0 auto;
                    margin-top: 172px;
                }
            }

        }
    }

    .template3 {
        text-align: center;
        margin-top: 130px;

        .info-wrap {
            display: flex;
            margin-top: 80px;

            .tabs {
                margin-left: 36px;
                flex: 1;
                height: 440px;

                .infinite-list {
                    max-height: 440px;
                    scrollbar-width: none;
                    /* firefox */
                    -ms-overflow-style: none;
                    /* IE 10+ */
                    overflow-x: hidden;
                    overflow-y: auto;

                }

                .item {
                    padding: 20px 0;
                    border-bottom: 1px solid #707070;

                    .top {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 14px;

                        .left {
                            display: flex;
                            align-items: center;

                            span {
                                max-width: 400px;
                                padding-left: 4px;
                                font-size: 18px;
                                /*1. 先强制一行内显示文本*/
                                white-space: nowrap;
                                /*2. 超出的部分隐藏*/
                                overflow: hidden;
                                /*3. 文字用省略号替代超出的部分*/
                                text-overflow: ellipsis;
                            }
                        }

                    }

                    .info {
                        text-align: left;
                        padding-left: 29px;

                        p {
                            width: 400px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            padding-top: 16px;
                            color: #6A6A6A;
                            line-height: 24px;
                        }
                    }
                }

                .more {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: #7FBE2C;
                    margin-top: 10px;
                }
            }

            ::v-deep.el-tabs__item.is-active {
                color: #7FBE2C;
                font-size: 20px;
            }

            ::v-deep.el-tabs__item {
                width: 150px;
                font-size: 20px;
            }

            ::v-deep.el-tabs__active-bar {
                background-color: #7FBE2C;
            }

            ::v-deep .el-tabs__item:hover {
                color: #7FBE2C;
            }
        }
    }
    .template4{
        margin-top: 130px;
        text-align: center;
        .teacher-info{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 80px;
            .item{
                width: 368px;
                margin-bottom: 103px;
                img{
                    width: 368px;
                    height: 238px;
                }
                .text-container{
                    .name{
                        font-size: 18px;
                        padding-top: 20px;
                    }
                    .t-info{
                        margin-top: 16px;
                        text-align: left;
                        font-size: 14px;
                        padding: 0 42px;
                    }
                };
            }
        }
    }
}
</style>